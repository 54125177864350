function rand_from_seed(x, iterations){
    iterations = iterations || 1000;
    for (var i = 0; i < iterations; i++)
        x = (x ^ (x << 1) ^ (x >> 1)) % 1000;
    return x;
}

export function getRandomSeedNum(mode, album) {
    if (mode === "") {
        var dateVal = new Date();
        dateVal = dateVal.getTime() - dateVal.getTimezoneOffset()*60000;
        var random = rand_from_seed(~~(dateVal.valueOf()/86400000));

        return random / Math.pow(10, random.toString().length)
    } else if ((mode === "endless" || mode === "2024") && album !== "") {
        let hash = 0;
        for (let i = 0; i < album.length; i++) {
            hash = album.charCodeAt(i) + ((hash << 5) - hash);
        }

        const a = 1664525;
        const c = 1013904223;
        const m = Math.pow(2, 32);
        hash = Math.abs(hash);
        let randomNumber = (a * hash + c) % m;
        randomNumber = randomNumber / m; // Normalize to 0-1 range

        return randomNumber
    } else {
        return Math.random();
    }
}