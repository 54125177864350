import {getCookie} from "./cookies";

export function setResults(colour, mode) {

    function setResultsForClassName(className) {
        var list = document.getElementsByClassName(`li_result${className}`);
        var resultNums = [];

        for (var i = 0; i < list.length; i++) {
            let el = document.getElementById(list[i].id);
            let count = getCookie(`${mode}pastresults${i + 1}`);
            if (count !== "") {
                if (colour !== null) {
                    el.style.backgroundColor = colour;
                }
                el.style.textAlign = 'right';
                el.innerHTML = count;
                resultNums.push(parseInt(count))
                // el.style.paddingRight = '10px';
                // let listColor = invertColour(colour, true);
                // el.style.color = listColor
                // let markerColor = invertColour(listColor, true);
                // document.querySelector('.li_result').style.setProperty('--markerColor', markerColor);
                // console.log(window.getComputedStyle(el, '::marker').color);
            }
        }

        for (var j = 0; j < list.length; j++) {
            let count = getCookie(`${mode}pastresults${j + 1}`);
            let el = document.getElementById(list[j].id);
            el.style.width = `100%`
            let maxWidth = el.clientWidth;

            let width = (maxWidth / Math.max.apply(Math, resultNums) * parseInt(count)).toFixed(1);
            if (count !== "") {
                el.style.width = `${width}px`
            } else {
                width = 0;
            }

            // weird formatting issue on iphones fix
            if (iphoneCheck()) {
                if (width > 56) {
                    el.style.paddingInlineStart = '72px';
                } else if (width > 0 && width < 20) {
                    el.style.paddingInlineStart = '0px';
                } else if (width <= 0) {
                    el.style.paddingInlineStart = '81px';
                } else {
                    el.style.paddingInlineStart = `${width - 10}px`
                }
            }
        }
    }

    setResultsForClassName("");
    setResultsForClassName("_download");
}

function iphoneCheck() {
    if (typeof window === `undefined` || typeof navigator === `undefined`) return false;

    return /iPhone/i.test(navigator.userAgent || navigator.vendor || (window.location.opera && window.location.opera.toString() === `[object Opera]`));
}