import './stylesheets/modal/modal.css';
import './stylesheets/modal/modalMobile.css';
import './stylesheets/modal/modalTablet.css';

import './stylesheets/resultsCard/resultsCard.css';
import './stylesheets/resultsCard/resultsCardMobile.css';

import React, {useEffect} from "react";

const Modal = (props) => {
  let {name, content, open, setOpen} = props;
  let modal = document.getElementById(name);

  useEffect(() => {
    if (modal !== null) {
      if (open) {
        modal.style.display = 'block';
      } else {
        modal.style.display = 'none';
      }
    }
  }, [open])

  return (
    <div id={name} className="modal text" onClick={() => {
      setOpen(false)
    }}>
      {content}
    </div>
  )
}

export default Modal;