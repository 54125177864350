import {getCookie} from "./cookies";
import {firstVisitScreenMode} from "./darkModeToggle";

export function checkFirstVisit(setHowToOpen, setTheme) {
    if (getCookie('firstVisit') === '') {
        firstVisitScreenMode(setTheme);
        // openCookieBanner();
    }
}

function openCookieBanner() {
    let banner = document.getElementById('cookie-content-banner');
    let bg = document.getElementById('cookie-bg');
    banner.style.visibility = 'visible';
    bg.style.visibility = 'visible';
}



