//get imgdata index from img px positions
function indexX(x, imgData) {
    var i = x * 4;
    if (i > imgData.length) console.warn("X out of bounds");
    return i;
}
function indexY(y, imgData, imgW) {
    var i = imgW * 4 * y;
    if (i > imgData.length) console.warn("Y out of bounds");
    return i;
}
function getIndex(x, y, imgData, imgW) {
    var i = indexX(x, imgData) + indexY(y, imgData, imgW);
    if (i > imgData.length) console.warn("XY out of bounds");
    return i;
}

//get a tile of size tileDim*tileDim from position xy
function getTile(x, y, tileDim, imgData, imgW) {
    var tile = [];
    //loop over rows
    for (var i = 0; i < tileDim; i++) {
        //slice original image from x to x + tileDim, concat
        tile.push(...imgData.slice(getIndex(x, y + i, imgData, imgW), getIndex(x + tileDim, y + i, imgData, imgW)));
    }
    //convert back to typed array and to imgdata object
    tile = new ImageData(new Uint8ClampedArray(tile), tileDim, tileDim);
    //save original position
    tile.x = x;
    tile.y = y;
    return tile;
}

//generate all tiles
export function getTiles(imgData, imgW, tileDim, tileCountX, tileCountY) {
    var tiles = [];
    for (var yi = 0; yi < tileCountY; yi++) {
        for (var xi = 0; xi < tileCountX; xi++) {
            tiles.push(getTile(xi * tileDim, yi * tileDim, tileDim, imgData, imgW));
        }
    }
    return tiles;
}