export function autocomplete(inp, arr) {
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function(e) {
        var possibleAlbumMatchesDiv, individualMatchDiv, i, val = this.value;
        /*close any already open lists of autocompleted values*/
        closeAllLists();
        if (!val) { return false;}
        currentFocus = -1;
        /*create a DIV element that will contain the items (values):*/
        possibleAlbumMatchesDiv = document.createElement("DIV");
        possibleAlbumMatchesDiv.setAttribute("id", this.id + "autocomplete-list");
        possibleAlbumMatchesDiv.setAttribute("class", "autocomplete-items");
        /*append the DIV element as a child of the autocomplete container:*/
        this.parentNode.appendChild(possibleAlbumMatchesDiv);
        /*for each item in the array...*/

        // Remove commas from both the input value and the array items before comparing
        const valWithoutCommas = val.toLowerCase().replace(/,/g, '');

        for (i = 0; i < arr.length; i++) {
            const arrItemWithoutCommas = arr[i].toString().toLowerCase().replace(/,/g, '');

            if (arrItemWithoutCommas.includes(valWithoutCommas)) {
                // position of first search character in array suggestion
                var posOfFirstValChar = arrItemWithoutCommas.indexOf(valWithoutCommas[0])

                if (valWithoutCommas.length > 1) {
                    var posOfSecondValChar = arrItemWithoutCommas.indexOf(valWithoutCommas[1])
                    if (posOfSecondValChar - posOfFirstValChar !== 1) {
                        posOfFirstValChar = arrItemWithoutCommas.indexOf(valWithoutCommas[0], posOfSecondValChar - 2)
                    } else {
                        if (valWithoutCommas.length > 2) {
                            var posOfThirdValChar = arrItemWithoutCommas.indexOf(valWithoutCommas[2])
                            if (posOfThirdValChar - posOfSecondValChar !== 1 && posOfSecondValChar - posOfFirstValChar !== 1) {
                                posOfFirstValChar = arrItemWithoutCommas.indexOf(valWithoutCommas[0], posOfThirdValChar - 3)
                            }
                        }
                    }
                }

                /*create a DIV element for each matching element:*/
                individualMatchDiv = document.createElement("DIV");
                /*make the matching letters bold:*/
                individualMatchDiv.innerHTML = arr[i].toString().substring(0, posOfFirstValChar);
                individualMatchDiv.innerHTML += "<strong>" + arr[i].toString().substring(posOfFirstValChar, posOfFirstValChar + valWithoutCommas.length) + "</strong>";
                individualMatchDiv.innerHTML += arr[i].toString().substring(posOfFirstValChar + valWithoutCommas.length);
                /*insert a input field that will hold the current array item's value:*/
                individualMatchDiv.innerHTML += "<input type='hidden' value='" + arr[i].toString().replace(/'/g,'&#x27;') + "'>";
                /*execute a function when someone clicks on the item value (DIV element):*/
                individualMatchDiv.addEventListener("click", function(e) {
                    /*insert the value for the autocomplete text field:*/
                    inp.value = this.getElementsByTagName("input")[0].value;
                    /*close the list of autocompleted values,
                    (or any other open lists of autocompleted values:*/
                    closeAllLists();
                });

                // Only add up to 10 albums, not every single match
                if (possibleAlbumMatchesDiv.children.length < 9) {
                    possibleAlbumMatchesDiv.appendChild(individualMatchDiv);
                } else {
                    break;
                }
            }
        }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function(e) {
        var x = document.getElementById(this.id + "autocomplete-list");
        if (x) x = x.getElementsByTagName("div");
        if (e.keyCode == 40) {
            /*If the arrow DOWN key is pressed,
            increase the currentFocus variable:*/
            currentFocus++;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 38) { //up
            /*If the arrow UP key is pressed,
            decrease the currentFocus variable:*/
            currentFocus--;
            /*and and make the current item more visible:*/
            addActive(x);
        } else if (e.keyCode == 13) {
            /*If the ENTER key is pressed, prevent the form from being submitted,*/
            e.preventDefault();
            if (currentFocus > -1) {
                /*and simulate a click on the "active" item:*/
                if (x) x[currentFocus].click();
            }
        }
    });
    function addActive(x) {
        /*a function to classify an item as "active":*/
        if (!x) return false;
        /*start by removing the "active" class on all items:*/
        removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
        /*add class "autocomplete-active":*/
        x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
        /*a function to remove the "active" class from all autocomplete items:*/
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }
    function closeAllLists(elmnt) {
        /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
        var x = document.getElementsByClassName("autocomplete-items");
        for (var i = 0; i < x.length; i++) {
            if (elmnt != x[i] && elmnt != inp) {
                x[i].parentNode.removeChild(x[i]);
            }
        }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
        closeAllLists(e.target);
    });
}